import {useSuperMutation} from '@vadiun/react-hooks';
import {Button, FormikTextInput, Text, TextInput} from '@vadiun/react-native-eevee';
import {useAuth} from 'app/auth/services/useAuth';
import {Field, Formik} from 'formik';
import React from 'react';
import {View, KeyboardAvoidingView, Platform, StyleSheet} from 'react-native';
import styled from 'styled-components/native';
import * as Yup from 'yup';

const Logo = require('assets/vaporeon.jpg');

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),
});

export const ForgotPasswordPage = () => {
  const {forgotPassword} = useAuth();

  const forgotPasswordMutations = useSuperMutation(forgotPassword, {
    successMessageType: 'message',
    messages: {
      success: {
        message:
          'Se ha enviado un mail a su casilla de correo con instrucciones para cambiar su contraseña',
      },
    },
  });
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : 0}
      style={{flex: 1}}>
      <Container contentContainerStyle={styles.container}>
        <View style={styles.welcome}>
          <Text weight="bold" size="9xl">
            ¿Olvidaste tu contraseña?
          </Text>
          <Text color="gray400" size={"xl"} style={{marginVertical: 20}}>Ingresá tu email y te enviaremos las instrucciones para modificar tu
            contraseña</Text>
        </View>

        <Text>{forgotPasswordMutations.error?.message}</Text>
        <Formik
          initialValues={{email: ''}}
          validationSchema={Schema}
          onSubmit={forgotPasswordMutations.mutate}>
          {({submitForm}) => (
            <View >
              <Field
                name="email"
                mode="flat"
                component={FormikTextInput}
                keyboardType="email-address"
                placeholder="Email"
                margin={"lg"}
                left={<TextInput.Icon name="mail-outline"/>}
              />

                <Button
                  onPress={submitForm}
                  size="lg"
                  mode="contained"
                  style={{marginTop: 50, marginBottom: 86}}
                  loading={forgotPasswordMutations.isLoading}
                  disabled={forgotPasswordMutations.isLoading}>
                  Continuar
                </Button>
            </View>
          )}
        </Formik>
      </Container>
    </KeyboardAvoidingView>
  );
};

const Container = styled.ScrollView`
  flex: 1;
`;

const LogoImage = styled.Image`
  height: 200px;
  width: 200px;
`;

const styles = StyleSheet.create({
  container: {
    padding: 35,
    flexGrow: 1,
  },
  logo: {width: 200, height: 200},
  buttons: {
    justifyContent: 'flex-end',
    marginTop: 50
  },
  welcome: {
    justifyContent: 'flex-end',
    flex: 1
  },
});
