import {Moment} from 'moment';

interface IEstablecimientoDisponibilidadHoraria {
  fecha: Moment;
  hora: string;
  minuto: string;
  disponibilidades: {
    intervalo: 60 | 90 | 120;
    canchas: {
      id: number;
      numero: number;
      tipo: string;
      es_techada: boolean;
      tiene_iluminacion: boolean;
      precio: number;
    }[];
  }[];
}

export class EstablecimientoDisponibilidadHoraria {
  fecha: Moment;
  hora: string;
  minuto: string;
  disponibilidades: {
    intervalo: 60 | 90 | 120;
    canchas: {
      id: number;
      numero: number;
      tipo: string;
      es_techada: boolean;
      tiene_iluminacion: boolean;
      precio: number;
    }[];
  }[];

  constructor(args: IEstablecimientoDisponibilidadHoraria) {
    this.fecha = args.fecha;
    this.hora = args.hora;
    this.minuto = args.minuto;
    this.disponibilidades = args.disponibilidades;
  }
}
