import {useSuperMutation} from '@vadiun/react-hooks';
import {
  Button,
  FormikTextInput,
  Text,
  TextInput,
} from '@vadiun/react-native-eevee';
import {useAuth} from 'app/auth/services/useAuth';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Yup from 'yup';
import {Link} from '@react-navigation/native';

const Schema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como mínimo 4 caracteres'),
  passwordRepeat: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como mínimo 4 caracteres')
    .test(
      'match',
      'Las contraseñas no coinciden',
      (value, context) => context.parent.password === value,
    ),
});

export const ChangePasswordPage = () => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [secureTextEntryRepeat, setSecureTextEntryRepeat] = useState(true);
  const {changePassword} = useAuth();
  const changePasswordMutation = useSuperMutation(changePassword, {
    messages: {
      success: {
        message: 'Contraseña actualizada',
      },
    },
  });

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={20}
      style={{flex: 1}}>
      <ScrollView contentContainerStyle={styles.container}>
        <Formik
          initialValues={{
            password: '',
            passwordRepeat: '',
          }}
          validationSchema={Schema}
          onSubmit={changePasswordMutation.mutate}>
          {({submitForm}) => (
            <View>
              <Field
                name="password"
                mode="flat"
                secureTextEntry={secureTextEntry}
                component={FormikTextInput}
                placeholder="Contraseña"
                margin="md"
                left={<TextInput.Icon name="lock-closed-outline" />}
                right={
                  secureTextEntry ? (
                    <TouchableOpacity onPress={() => setSecureTextEntry(false)}>
                      <TextInput.Icon name="eye-off-outline" />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => setSecureTextEntry(true)}>
                      <TextInput.Icon name="eye-outline" />
                    </TouchableOpacity>
                  )
                }
              />
              <Field
                name="passwordRepeat"
                mode="flat"
                secureTextEntry={secureTextEntryRepeat}
                component={FormikTextInput}
                placeholder="Repita su contraseña"
                left={<TextInput.Icon name="lock-closed-outline" />}
                margin="md"
                right={
                  secureTextEntryRepeat ? (
                    <TouchableOpacity
                      onPress={() => setSecureTextEntryRepeat(false)}>
                      <TextInput.Icon name="eye-off-outline" />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      onPress={() => setSecureTextEntryRepeat(true)}>
                      <TextInput.Icon name="eye-outline" />
                    </TouchableOpacity>
                  )
                }
              />

              <Button
                onPress={submitForm}
                loading={changePasswordMutation.isLoading}
                style={{marginTop: 20, marginBottom: 86}}
                size="lg"
                disabled={changePasswordMutation.isLoading}>
                Guardar
              </Button>
            </View>
          )}
        </Formik>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 35,
    flexGrow: 1,
  },
  logo: {width: 200, height: 200},
  buttons: {
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  welcome: {
    justifyContent: 'flex-end',
    flex: 1,
  },
});
