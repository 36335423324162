import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Text, Icon, useTheme} from '@vadiun/react-native-vaporeon';
import {useAuth} from 'app/auth/services/useAuth';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {MoreNavigationType} from '../navigation';
import useWhatsapp from 'app/main/services/useWhatapp';
import {useWebpage} from 'app/main/services/useWebpage';
export const MorePage = () => {
  const navigation = useNavigation<NavigationProp<MoreNavigationType>>();
  const authSrv = useAuth();
  const theme = useTheme();
  const whatsapp = useWhatsapp();
  const webpage = useWebpage();
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.navigate('edit')}
        style={[styles.button, {borderColor: theme.colors.dark800}]}>
        <View style={styles.buttonLabel}>
          <Icon name="person-outline" style={{marginRight: 10}} size={20} />
          <Text size="xl">Mi perfil</Text>
        </View>
        <Icon name="chevron-forward-outline" size={20} />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => webpage.open('https://wa.me/5491126947733')}
        style={[styles.button, {borderColor: theme.colors.dark800}]}>
        <View style={styles.buttonLabel}>
          <Icon
            name="information-circle-outline"
            style={{marginRight: 10}}
            size={20}
          />
          <Text size="xl">Soporte</Text>
        </View>
        <Icon name="chevron-forward-outline" size={20} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          webpage.open('https://instagram.com/mipadelapp?igshid=YmMyMTA2M2Y=')
        }
        style={[styles.button, {borderColor: theme.colors.dark800}]}>
        <View style={styles.buttonLabel}>
          <Icon name="logo-instagram" style={{marginRight: 10}} size={20} />
          <Text size="xl">Instagram</Text>
        </View>

        <Icon name="chevron-forward-outline" size={20} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          webpage.open('https://admin-dev.mipadel.com.ar/terminos-condiciones')
        }
        style={[styles.button, {borderColor: theme.colors.dark800}]}>
        <View style={styles.buttonLabel}>
          <Icon name="newspaper-outline" style={{marginRight: 10}} size={20} />
          <Text size="xl">Términos y condiciones</Text>
        </View>
        <Icon name="chevron-forward-outline" size={20} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => authSrv.logout()}
        style={[styles.button, {borderColor: theme.colors.dark800}]}>
        <View style={styles.buttonLabel}>
          <Icon name="exit-outline" style={{marginRight: 10}} size={20} />
          <Text size="xl">Salir</Text>
        </View>
        <Icon name="chevron-forward-outline" size={20} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 35,
  },
  button: {
    padding: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
  },
  buttonLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
