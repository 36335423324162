import {useEffect, useState} from 'react';
import * as Location from 'expo-location';
import {useMessage, useSpinner} from '@vadiun/react-hooks';
import {LocationAccuracy} from 'expo-location';

export const useLocation = () => {
  const showMeessage = useMessage();

  const getLocation = async () => {
    const permission = await Location.getForegroundPermissionsAsync();
    let permissionGranted =
      permission.status === Location.PermissionStatus.GRANTED;
    if (!permissionGranted) {
      permissionGranted = (await Location.requestForegroundPermissionsAsync())
        .granted;
    }
    if (permissionGranted) {
      return await Location.getCurrentPositionAsync({
        accuracy: LocationAccuracy.Balanced,
      });
    }
    showMeessage({
      type: 'info',
      message:
        'Activa la ubicación para poder encontrar las canchas mas cercanas a tu posición',
    });
    throw new Error('Permission denied');
  };

  return getLocation;
};

/*export const useLocation = () => {
  const showMeessage = useMessage();
  const [location, setLocation] = useState<Location.LocationObject>();
  const [permissionGranted, setPermissionGranted] = useState<
    boolean | undefined
  >();
  useEffect(() => {
    Location.getForegroundPermissionsAsync().then(permission => {
      setPermissionGranted(permission.granted);
      if (permission.granted) {
        saveLocation();
      } else {
      }
    });
  }, []);

  const saveLocation = () => {
    const locationPromise = Location.getCurrentPositionAsync({
      accuracy: LocationAccuracy.Balanced,
    });
    locationPromise.then(location => {
      setLocation(location);
    });
  };

  const requestPermission = async () => {
    const permission = await Location.requestForegroundPermissionsAsync();
    debugger;
    if (permission.granted) {
      setPermissionGranted(true);
      saveLocation();
    }
  };

  return {
    location,
    permissionGranted,
    requestPermission,
  };
};
*/
