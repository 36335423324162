import {
  Badge,
  BottomSheetModal,
  Button,
  Chip,
  FlatList,
  Icon,
  Section,
  Surface,
  Text,
  useTheme,
} from '@vadiun/react-native-vaporeon';
import moment, {Moment} from 'moment';
import React, {useState} from 'react';
import {Dimensions, StyleSheet, TouchableOpacity, View} from 'react-native';
import {useEstablecimientoReservaProvider} from '../services/useEstablecimientoReservaProvider';

const getDiasDelProximoMes = () => {
  const dias = [];

  for (let i = 0; i <= 7; i++) {
    dias.push(moment().add(i, 'days'));
  }

  return dias;
};

const dias = getDiasDelProximoMes();

const nombresDias = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

export const EstablecimientoReservaBottomSheetComponent = () => {
  const [pageSelected, setPageSelected] = React.useState(0);
  const theme = useTheme();
  const {
    daySelected,
    setDaySelected,
    disponibilidadHoraria,
    createReservaRef,
    seña,
    disponibilidadHorariaLoading,
  } = useEstablecimientoReservaProvider();

  const [hourSelected, setHourSelected] = useState<
    {hora: string; minuto: string} | undefined
  >(undefined);
  const [intervalSelected, setIntervalSelected] = useState<
    60 | 90 | 120 | undefined
  >(undefined);
  const [canchaIdSelected, setCanchaIdSelected] = useState<number | undefined>(
    undefined,
  );
  const horariosDisponibles =
    disponibilidadHoraria === undefined
      ? undefined
      : disponibilidadHoraria
          .filter(horario => {
            return horario.disponibilidades.some(h => h.canchas.length > 0);
          })
          .map(horario => ({
            hora: horario.hora,
            minuto: horario.minuto,
          }));

  let intervalosDisponibles;
  if (disponibilidadHoraria !== undefined && hourSelected !== undefined) {
    intervalosDisponibles = [];
    const disponibilidadesDeLaHoraSeleccionada = disponibilidadHoraria.find(
      d => d.hora === hourSelected.hora && d.minuto === hourSelected.minuto,
    );
    if (disponibilidadesDeLaHoraSeleccionada === undefined) {
      console.error('Hora no encontrada');
      return;
    }
    const disponibilidad60 =
      disponibilidadesDeLaHoraSeleccionada.disponibilidades.find(
        d => d.intervalo === 60,
      );
    const disponibilidad90 =
      disponibilidadesDeLaHoraSeleccionada.disponibilidades.find(
        d => d.intervalo === 90,
      );
    const disponibilidad120 =
      disponibilidadesDeLaHoraSeleccionada.disponibilidades.find(
        d => d.intervalo === 120,
      );
    if (disponibilidad60 !== undefined && disponibilidad60.canchas.length > 0) {
      intervalosDisponibles.push(60);
    }
    if (disponibilidad90 !== undefined && disponibilidad90.canchas.length > 0) {
      intervalosDisponibles.push(90);
    }
    if (
      disponibilidad120 !== undefined &&
      disponibilidad120.canchas.length > 0
    ) {
      intervalosDisponibles.push(120);
    }
  }

  let canchasDisponibles;

  if (
    intervalSelected !== undefined &&
    hourSelected !== undefined &&
    disponibilidadHoraria !== undefined
  ) {
    const disponibilidadesDeLaHoraSeleccionada = disponibilidadHoraria.find(
      d => d.hora === hourSelected.hora && d.minuto === hourSelected.minuto,
    );
    if (disponibilidadesDeLaHoraSeleccionada === undefined) {
      throw new Error('Hora no encontrada');
    }
    const disponibilidadDelIntervaloSeleccionado =
      disponibilidadesDeLaHoraSeleccionada.disponibilidades.find(
        d => d.intervalo === intervalSelected,
      );
    if (disponibilidadDelIntervaloSeleccionado === undefined) {
      throw new Error('Intervalo no encontrada');
    }
    canchasDisponibles = disponibilidadDelIntervaloSeleccionado.canchas;
  }

  const onSelectDay = (day: Moment) => {
    setDaySelected(day);
    setHourSelected(undefined);
    setIntervalSelected(undefined);
    setCanchaIdSelected(undefined);
  };

  const onSelectHour = (hour: {hora: string; minuto: string}) => {
    setHourSelected(hour);
    setIntervalSelected(undefined);
    setCanchaIdSelected(undefined);
  };

  const onSelectInterval = (interval: 60 | 90 | 120) => {
    setIntervalSelected(interval);
    setCanchaIdSelected(undefined);
  };

  const onCreateReserva = async () => {
    if (
      createReservaRef.current &&
      daySelected &&
      hourSelected &&
      intervalSelected &&
      canchaIdSelected
    ) {
      await createReservaRef.current({
        cancha_id: canchaIdSelected,
        fecha: daySelected,
        hora_inicio: hourSelected,
        intervalo: intervalSelected,
      });
    }
  };

  return (
    <BottomSheetModal
      height={700}
      name="EstablecimientoReservaBottomSheetComponent">
      <View>
        <Section>
          <Section.Title>Día de la reserva</Section.Title>
          <FlatList
            data={dias}
            style={{marginTop: 10}}
            showsHorizontalScrollIndicator={false}
            renderItem={({item, index}) => (
              <TouchableOpacity
                onPress={() => onSelectDay(item)}
                key={index}
                disabled={disponibilidadHorariaLoading}>
                <Surface
                  elevation={5}
                  style={[
                    styles.chipDiaContainer,
                    {
                      borderColor: theme.colors.primary500,
                      borderWidth: daySelected === item ? 2 : 0,
                    },
                  ]}>
                  <Text size="md">{item.format('DD')}</Text>
                  <Text size="xs" style={{fontSize: 10}}>
                    {nombresDias[item.day()]}
                  </Text>
                </Surface>
              </TouchableOpacity>
            )}
            horizontal
            ItemSeparatorComponent={() => <View style={{width: 15}} />}
          />

          <Section.Title style={{marginTop: 20}}>Hora de inicio</Section.Title>
          {horariosDisponibles === undefined ? (
            <Text>Seleccione el día de la reserva</Text>
          ) : (
            <FlatList
              data={horariosDisponibles}
              style={{marginTop: 10}}
              showsHorizontalScrollIndicator={false}
              renderItem={({item}) => (
                <Chip
                  disabled={disponibilidadHorariaLoading}
                  onPress={() => onSelectHour(item)}
                  color={
                    hourSelected?.hora === item.hora &&
                    hourSelected?.minuto === item.minuto
                      ? 'primary500'
                      : 'light50'
                  }>
                  {moment(
                    `2000-01-01 ${item.hora}:${item.minuto}`,
                    'YYYY-MM-DD HH:mm',
                  ).format('HH:mm')}
                </Chip>
              )}
              horizontal
              ListEmptyComponent={<Text>No hay horarios disponibles</Text>}
              ItemSeparatorComponent={() => <View style={{width: 10}} />}
            />
          )}

          <Section.Title style={{marginTop: 20}}>
            Duración del turno
          </Section.Title>
          {intervalosDisponibles === undefined ? (
            <Text>Seleccione la hora de inicio</Text>
          ) : (
            <FlatList
              data={intervalosDisponibles}
              style={{marginTop: 10}}
              renderItem={({item}) => (
                <Chip
                  disabled={disponibilidadHorariaLoading}
                  onPress={() => onSelectInterval(item as 60 | 90 | 120)}
                  color={intervalSelected === item ? 'primary500' : 'light50'}>
                  {item + "'"}
                </Chip>
              )}
              horizontal
              ItemSeparatorComponent={() => <View style={{width: 10}} />}
            />
          )}
          <Section.Title style={{marginTop: 20}}>
            Seleccionar cancha{' '}
            <Text weight="medium">
              ({canchasDisponibles?.length ?? 0} disponibles)
            </Text>
          </Section.Title>
          {canchasDisponibles === undefined ? (
            <Text>Seleccione la duración del turno</Text>
          ) : (
            <>
              <FlatList
                data={canchasDisponibles}
                pagingEnabled
                style={{marginTop: 10}}
                showsHorizontalScrollIndicator={false}
                onScroll={ev => {
                  setPageSelected(
                    Math.floor(
                      ev.nativeEvent.contentOffset.x /
                        (Dimensions.get('window').width - 40),
                    ),
                  );
                }}
                renderItem={({item}) => (
                  <TouchableOpacity
                    onPress={() => setCanchaIdSelected(item.id)}
                    key={item.id}>
                    <Surface
                      elevation={3}
                      style={[
                        styles.canchaContainer,
                        {
                          borderColor: theme.colors.primary500,
                          borderWidth: canchaIdSelected === item.id ? 2 : 0,
                        },
                      ]}>
                      <View style={{flexDirection: 'row', flex: 1}}>
                        <View
                          style={[
                            styles.canchaIndex,
                            {backgroundColor: theme.colors.light50},
                          ]}>
                          <Text color={'dark900'}>{item.numero}</Text>
                        </View>
                        <View style={{marginLeft: 20}}>
                          <Text size="xs">Tipo: {item.tipo}</Text>
                          {item.es_techada ? (
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <Icon
                                name="checkmark"
                                color={'green500'}
                                size={15}
                              />
                              <Text size="xs" style={{marginLeft: 5}}>
                                Techada
                              </Text>
                            </View>
                          ) : null}
                          {item.tiene_iluminacion ? (
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <Icon
                                name="checkmark"
                                color={'green500'}
                                size={15}
                              />
                              <Text size="xs" style={{marginLeft: 5}}>
                                Iluminada
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      </View>
                      <View
                        style={{
                          marginLeft: 10,
                        }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}>
                          <Text
                            size="xs"
                            style={{marginRight: 7}}
                            color="text300">
                            Precio
                          </Text>
                          <Badge color="green500">{'$' + item.precio}</Badge>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginTop: 7,
                          }}>
                          <Text
                            size="xs"
                            style={{marginRight: 7}}
                            color="text300">
                            Seña
                          </Text>
                          <Badge color="dark600">{'$' + seña}</Badge>
                        </View>
                      </View>
                    </Surface>
                  </TouchableOpacity>
                )}
                horizontal
                ItemSeparatorComponent={() => <View style={{width: 10}} />}
              />
              <View style={styles.pagination}>
                {Array(canchasDisponibles?.length ?? 0)
                  .fill(0)
                  .map((_, index) => (
                    <View
                      key={index}
                      style={[
                        styles.paginationDot,
                        {
                          backgroundColor:
                            pageSelected === index
                              ? theme.colors.primary500
                              : theme.colors.white,
                        },
                      ]}
                    />
                  ))}
              </View>
            </>
          )}
        </Section>
        <View style={{marginTop: 20, paddingBottom: 20}}>
          <Button
            size="xl"
            disabled={
              canchaIdSelected === undefined || disponibilidadHorariaLoading
            }
            onPress={onCreateReserva}>
            Reservar
          </Button>
        </View>
      </View>
    </BottomSheetModal>
  );
};

EstablecimientoReservaBottomSheetComponent.modalOptions = {
  position: 'bottom',
};

const styles = StyleSheet.create({
  chipDiaContainer: {
    width: 30,
    height: 50,
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 15,
    alignItems: 'center',
  },
  canchaContainer: {
    width: Dimensions.get('window').width - 40,
    flexDirection: 'row',
    padding: 20,
    borderRadius: 15,
  },
  canchaIndex: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  pagination: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 2,
  },
});
