import {View} from 'react-native';
import {Icon} from '@vadiun/react-native-vaporeon';
import React from 'react';

export function CalificacionComponent(props: {calificacion: number}) {
  return (
    <View style={{flexDirection: 'row'}}>
      {Array(props.calificacion)
        .fill(0)
        .map((_, index) => (
          <Icon
            color="yellow500"
            style={{marginRight: 5}}
            key={index}
            name={'star'}
          />
        ))}
      {Array(5 - props.calificacion)
        .fill(0)
        .map((_, index) => (
          <Icon
            color="gray500"
            style={{marginRight: 5}}
            key={index}
            name={'star'}
          />
        ))}
    </View>
  );
}
