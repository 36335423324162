import {httpClient} from 'app/main/services/httpClient';
import {EstablecimientoResumen} from 'app/establecimientos/models/EstablecimientoResumen';
import {Establecimiento} from 'app/establecimientos/models/Establecimiento';
import {Cancha} from 'app/establecimientos/models/Cancha';
import moment, {Moment} from 'moment';
import {EstablecimientoDisponibilidadHoraria} from 'app/establecimientos/models/EstablecimientoDisponibilidadHoraria';

export const getEstablecimientoResumenMapper = (
  x: any,
): EstablecimientoResumen => new EstablecimientoResumen(x);

const getEstablecimientoMapper = (x: any): Establecimiento =>
  new Establecimiento({
    ...x,
    canchas: x.canchas.map((c: any) => new Cancha(c)),
  });

const getHorarioMapper = (x: any): EstablecimientoDisponibilidadHoraria => {
  return new EstablecimientoDisponibilidadHoraria({
    ...x,
    fecha: moment(x.fecha),
    hora: x.hora_inicio,
    minuto: x.minuto_inicio,
    disponibilidades: x.disponibilidades.map((d: any) => ({
      ...d,
      canchas: d.canchas.map((c: any) => new Cancha(c)),
    })),
  });
};

export class EstablecimientosRepository {
  getAll = async (filters: {
    latitude: number | null;
    longitude: number | null;
    favorites: boolean;
    turnosLibresHoy: boolean;
    blindex: boolean;
    fecha: string | undefined;
    hora: string | undefined;
    duracion: string | undefined;
  }) => {
    const params = [];
    if (filters.latitude && filters.longitude) {
      params.push(`latitude=${filters.latitude}`);
      params.push(`longitude=${filters.longitude}`);
    }
    if (filters.favorites) {
      params.push('favorito=1');
    }
    if (filters.blindex) {
      params.push('blindex=1');
    }
    if (filters.turnosLibresHoy) {
      params.push('turno_libre=1');
    }
    if (filters.fecha && filters.hora) {
      const fecha = moment(filters.fecha + ' ' + filters.hora);
      params.push('fecha_hora=' + fecha.format('YYYY-MM-DD HH:mm'));
    }
    if (filters.duracion) {
      params.push('intervalo=' + filters.duracion);
    }

    const res = await httpClient.get<any[]>(
      'establecimientos?' + params.join('&'),
    );
    return res.map(getEstablecimientoResumenMapper);
  };

  get = async (id: number) => {
    const res = await httpClient.get(`establecimientos/${id}`);
    return getEstablecimientoMapper(res);
  };

  async getHorarios(
    id: string | number,
    fecha: Moment,
  ): Promise<EstablecimientoDisponibilidadHoraria[]> {
    const res = await httpClient.get(
      `establecimientos/${id}/disponibilidad-horaria?fecha=${fecha.format(
        'YYYY-MM-DD',
      )}`,
    );
    return res.map(getHorarioMapper);
  }

  async addToFavorites(idEstablecimiento: number) {
    return httpClient.post(`establecimientos/${idEstablecimiento}/favoritos`);
  }

  async removeFromFavorites(idEstablecimiento: number) {
    return httpClient.delete(`establecimientos/${idEstablecimiento}/favoritos`);
  }

  async getFavoritos() {
    const res = await httpClient.get<any[]>('establecimientos?favorito=1');
    return res.map(getEstablecimientoResumenMapper);
  }
}
