import {Linking} from 'react-native';

const useWhatsapp = () => {
  const sendMessage = ({
    message = '',
    phoneNumber = '',
  }: {
    message?: string;
    phoneNumber?: string;
  }) => {
    const params = [];
    if (message) {
      params.push(`text=${message}`);
    }
    let link = `https://wa.me/${phoneNumber}?`;
    if (params.length > 0) {
      link = `${link}?${params.join('&')}`;
    }
    Linking.openURL(link);
  };

  return {sendMessage};
};

export default useWhatsapp;
