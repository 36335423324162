import {StyleSheet, View} from 'react-native';
import React from 'react';
import {
  TextInput,
  useTheme,
  Text,
  Button,
  RadioButton,
} from '@vadiun/react-native-vaporeon';
import {unstable_createElement} from 'react-native-web';
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {EstablecimientosNavigationType} from '../navigation';

type Props = {};

type DateInputProps = {
  onChange: (date: string) => void;
  value: string;
};

const DateInput = (props: DateInputProps) => {
  const theme = useTheme();
  return unstable_createElement('input', {
    ...props,
    onChange: (ev: any) => props.onChange(ev.target.value),
    type: 'date',
    style: {
      fontFamily: theme.typhography.fonts.medium,
      backgroundColor: theme.darkMode
        ? theme.colors.dark800
        : theme.colors.light100,
      padding: 15,
      borderRadius: 10,
      fontSize: 16,
      borderWidth: 0,
      color: theme.darkMode ? theme.colors.text100 : theme.colors.text700,
      colorScheme: 'dark',
      width: '100%',
    },
  });
};

type TimeInputProps = {
  onChange: (date: string) => void;
  value: string;
};

const TimeInput = (props: TimeInputProps) => {
  const theme = useTheme();
  return unstable_createElement('input', {
    ...props,
    onChange: (ev: any) => props.onChange(ev.target.value),
    type: 'time',
    style: {
      fontFamily: theme.typhography.fonts.medium,
      backgroundColor: theme.darkMode
        ? theme.colors.dark800
        : theme.colors.light100,
      padding: 15,
      borderRadius: 10,
      fontSize: 16,
      borderWidth: 0,
      color: theme.darkMode ? theme.colors.text100 : theme.colors.text700,
      colorScheme: 'dark',
      width: '100%',
    },
  });
};

export const EstablecimientoDateFilterPage = (props: Props) => {
  const route =
    useRoute<RouteProp<EstablecimientosNavigationType, 'filtroFechas'>>();
  const [date, setDate] = React.useState(route.params?.fecha ?? '');
  const [time, setTime] = React.useState(route.params?.hora ?? '');
  const [intervalo, setIntervalo] = React.useState(
    route.params?.duracion ?? '',
  );
  const navigation =
    useNavigation<NavigationProp<EstablecimientosNavigationType>>();

  return (
    <View style={styles.container}>
      <Text>Fecha</Text>
      <View style={{marginRight: 35}}>
        <DateInput value={date} onChange={setDate} />
      </View>
      <Text style={{marginTop: 10}}>Hora</Text>
      <View style={{marginRight: 35}}>
        <TimeInput value={time} onChange={setTime} />
      </View>
      <RadioButton.Group
        value={intervalo}
        onChange={setIntervalo}
        label="Duración">
        <View style={{flexDirection: 'row'}}>
          <RadioButton.Item label="60'" value="60" />
          <RadioButton.Item label="90'" value="90" />
          <RadioButton.Item label="120'" value="120" />
        </View>
      </RadioButton.Group>
      <View style={styles.buttonContainer}>
        <Button
          size="lg"
          type="outlined"
          style={{marginRight: 10, flex: 1}}
          onPress={() => {
            navigation.navigate('listado');
          }}>
          Limpiar
        </Button>
        <Button
          size="lg"
          style={{marginLeft: 10, flex: 1}}
          disabled={date === '' || time === '' || intervalo === ''}
          onPress={() => {
            navigation.navigate('listado', {
              fecha: date,
              hora: time,
              duracion: intervalo,
            });
          }}>
          Buscar
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 35,
    paddingTop: 0,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});
