import React from 'react';
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';
import {
  EstablecimientosNavigation,
  EstablecimientosNavigationType,
} from 'app/establecimientos/navigation';
import {useTheme} from '@vadiun/react-native-vaporeon';
import {ReservasNavigation} from 'app/reservas/navigation';
import {MorePage} from 'app/more/pages/MorePage';
import {NavigatorScreenParams, useRoute} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {ReservationSuccessPage} from 'app/reservas/pages/ReservationSuccessPage';
import {MoreNavigation, MoreNavigationType} from 'app/more/navigation';

export type RootNavigationType = {
  establecimientos: NavigatorScreenParams<EstablecimientosNavigationType>;
  more: NavigatorScreenParams<MoreNavigationType>;
  reservas: undefined;
};

const Tab = createMaterialBottomTabNavigator<RootNavigationType>();
const Stack = createStackNavigator();
export const MainNavigation = () => {
  const theme = useTheme();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="main"
        options={{
          headerShown: false,
        }}>
        {() => (
          <Tab.Navigator
            activeColor={theme.colors.primary500}
            inactiveColor={theme.colors.text300}
            labeled={false}
            initialRouteName="establecimientos"
            shifting>
            <Tab.Screen
              name="establecimientos"
              component={EstablecimientosNavigation}
              options={{tabBarIcon: 'tennis'}}
            />
            <Tab.Screen
              name="reservas"
              component={ReservasNavigation}
              options={{tabBarIcon: 'format-list-bulleted'}}
            />
            <Tab.Screen
              name="more"
              component={MoreNavigation}
              options={({navigation}) => ({
                tabBarIcon: 'dots-horizontal',
              })}
            />
          </Tab.Navigator>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="reservaCreada"
        options={{
          headerShown: false,
        }}>
        {() => <ReservationSuccessPage />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
