import {getEstablecimientoResumenMapper} from 'app/establecimientos/services/establecimientosRepository';
import {httpClient} from 'app/main/services/httpClient';
import {Reserva} from 'app/reservas/models/Reserva';
import moment, {Moment} from 'moment';

export const getReservaMapper = (x: any): Reserva => {
  console.log(x.fecha_inicio);
  console.log(moment(x.fecha_inicio).hour());
  const fechaInicio =
    moment(x.fecha_inicio).hour() <= 4
      ? moment(x.fecha_inicio).add(1, 'day')
      : moment(x.fecha_inicio);
  const fechaFin =
    moment(x.fecha_fin).hour() <= 4
      ? moment(x.fecha_fin).add(1, 'day')
      : moment(x.fecha_fin);

  return new Reserva({
    ...x,
    fecha_inicio: fechaInicio,
    fecha_fin: fechaFin,
    establecimiento: getEstablecimientoResumenMapper(x.establecimiento),
  });
};

export interface ICreateReserva {
  cancha_id: number;
  fecha: Moment;
  hora_inicio: {hora: string; minuto: string};
  intervalo: 60 | 90 | 120;
}

export const createReservaMapper = (x: ICreateReserva) => x;

export class ReservasRepository {
  getAll = async () => {
    const res = await httpClient.get<any[]>('jugadores/me/reservas');
    return res.map(getReservaMapper);
  };

  get = async (id: number) => {
    const res = await httpClient.get(`reservas/${id}`);
    return getReservaMapper(res);
  };

  cancel = (id: string) => httpClient.delete(`reservas/${id}/cancelar`);

  async createReserva(reserva: ICreateReserva) {
    const fechaInicio = moment(
      `${reserva.fecha.format('YYYY-MM-DD')} ${reserva.hora_inicio.hora}:${
        reserva.hora_inicio.minuto
      }`,
      'YYYY-MM-DD HH:mm',
    );
    const data = {
      cancha_id: reserva.cancha_id,
      fecha_inicio: fechaInicio.format('YYYY-MM-DD HH:mm'),
      fecha_fin: fechaInicio
        .add(reserva.intervalo, 'minutes')
        .format('YYYY-MM-DD HH:mm'),
    };
    const res = await httpClient.post<{preference_url: string}>(
      'reservas',
      data,
    );

    return res;
  }
}
