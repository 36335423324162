interface IJugador {
  email: string;
  nombre: string;
  telefono: string;
}

export class Jugador {
  email: string;
  nombre: string;
  telefono: string;

  constructor(args: IJugador) {
    this.email = args.email;
    this.nombre = args.nombre;
    this.telefono = args.telefono;
  }
}
