import {StyleSheet, View} from 'react-native';
import React, {useCallback} from 'react';
import {ReservasRepository} from '../services/reservasRepository';
import {
  useSuperMutation,
  useSuperQuery,
  useVerifyAction,
} from '@vadiun/react-hooks';
import {Reserva} from '../models/Reserva';
import {FlatList, Surface, Text} from '@vadiun/react-native-vaporeon';
import {ReservaListItemComponent} from '../components/ReservaListItemComponent';
import {useFocusEffect} from '@react-navigation/native';
import {RefreshControl} from 'react-native-web-refresh-control';

type Props = {
  repo: ReservasRepository;
};

const MisReservasPage = ({repo}: Props) => {
  const verifyAction = useVerifyAction();
  const misReservasQuery = useSuperQuery(repo.getAll);
  const cancelReservaMutation = useSuperMutation(repo.cancel, {
    showSuccessMessage: false,
    onMutationFinish() {
      misReservasQuery.reload();
    },
  });
  useFocusEffect(
    useCallback(() => {
      misReservasQuery.reload();
    }, []),
  );

  return (
    <FlatList<Reserva>
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
      renderItem={({item}) => (
        <Surface elevation={2} style={{borderRadius: 20, width: '100%'}}>
          <ReservaListItemComponent
            reserva={item}
            onCancel={() => {
              verifyAction({
                body: '¿Estás seguro que desea cancelar la reserva?',
                onAccept: () => cancelReservaMutation.mutate(item.id),
              });
            }}
          />
        </Surface>
      )}
      refreshControl={
        <RefreshControl
          refreshing={misReservasQuery.isLoading}
          onRefresh={misReservasQuery.reload}
        />
      }
      ListHeaderComponent={
        <View style={{marginBottom: 15, marginTop: 35}}>
          <Text size="xl">Mis reservas ({misReservasQuery.data?.length})</Text>
        </View>
      }
      ListFooterComponent={<View style={{marginBottom: 35}} />}
      ItemSeparatorComponent={() => <View style={{marginBottom: 20}} />}
      loading={misReservasQuery.isLoading}
      data={misReservasQuery.data ?? []}
    />
  );
};

export default MisReservasPage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 35,
  },
});
