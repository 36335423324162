import {ICreateReserva} from 'app/reservas/services/reservasRepository';
import {Moment} from 'moment';
import React, {MutableRefObject, useContext, useRef, useState} from 'react';
import {EstablecimientoDisponibilidadHoraria} from '../models/EstablecimientoDisponibilidadHoraria';

interface EstablecimientoReservaContextType {
  seña: number | undefined;
  setSeña: (seña: number) => void;
  daySelected: Moment | undefined;
  setDaySelected: (day: Moment) => void;
  disponibilidadHoraria: EstablecimientoDisponibilidadHoraria[] | undefined;
  setDisponibilidadHoraria: (
    disponibilidad: EstablecimientoDisponibilidadHoraria[],
  ) => void;
  setDisponibilidadHorariaLoading: (loading: boolean) => void;
  disponibilidadHorariaLoading: boolean;
  createReservaRef: MutableRefObject<
    ((reserva: ICreateReserva) => Promise<any>) | null
  >;
}
const EstablecimientoReservaContext = React.createContext(
  {} as EstablecimientoReservaContextType,
);

export const EstablecimientoReservaProvider = (props: {
  children: React.ReactNode;
}) => {
  const [daySelected, setDaySelected] = useState<Moment | undefined>(undefined);
  const [seña, setSeña] = useState<number | undefined>(undefined);

  const [disponibilidadHoraria, setDisponibilidadHoraria] = useState<
    EstablecimientoDisponibilidadHoraria[] | undefined
  >(undefined);
  const [disponibilidadHorariaLoading, setDisponibilidadHorariaLoading] =
    useState(false);

  const createReservaRef = useRef<
    ((reserva: ICreateReserva) => Promise<any>) | null
  >(null);

  return (
    <EstablecimientoReservaContext.Provider
      value={{
        daySelected,
        disponibilidadHorariaLoading,
        setDisponibilidadHorariaLoading,
        seña,
        setSeña,
        setDaySelected,
        disponibilidadHoraria,
        setDisponibilidadHoraria,
        createReservaRef,
      }}
      {...props}
    />
  );
};

export const useEstablecimientoReservaProvider = () => {
  const context = useContext(EstablecimientoReservaContext);
  if (context.setDisponibilidadHoraria === undefined) {
    throw new Error(
      'useEstablecimientoReservaProvider must be used inside EstablecimientoReservaProvider',
    );
  }
  return context;
};
