/* eslint-disable react-hooks/exhaustive-deps */
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/core';
import {useMessage, useSuperMutation, useSuperQuery} from '@vadiun/react-hooks';
import {
  Badge,
  Button,
  CheckItem,
  Icon,
  PageDetail,
  Section,
  Text,
  useModal,
} from '@vadiun/react-native-vaporeon';
import {CalificacionComponent} from 'app/establecimientos/components/CalificacionComponent';
import {EstablecimientosNavigationType} from 'app/establecimientos/navigation';
import {EstablecimientosRepository} from 'app/establecimientos/services/establecimientosRepository';
import {RootNavigationType} from 'app/main/navigation';
import {useNavigationApp} from 'app/main/services/useNavigationApp';
import {useWebpage} from 'app/main/services/useWebpage';
import useWhatsapp from 'app/main/services/useWhatapp';
import {
  ICreateReserva,
  ReservasRepository,
} from 'app/reservas/services/reservasRepository';
import React, {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import {useEstablecimientoReservaProvider} from '../services/useEstablecimientoReservaProvider';
const Background = require('assets/background.png');

interface Props {
  repo: EstablecimientosRepository;
  reservaRepo: ReservasRepository;
}

export const EstablecimientoDetailPage = ({repo, reservaRepo}: Props) => {
  const modal = useModal();
  const webPage = useWebpage();
  const whatsapp = useWhatsapp();
  const navigationApp = useNavigationApp();
  const showMessage = useMessage();
  const {
    daySelected,
    setDisponibilidadHoraria,
    createReservaRef,
    setSeña,
    setDisponibilidadHorariaLoading,
  } = useEstablecimientoReservaProvider();
  const navigation = useNavigation<NavigationProp<RootNavigationType>>();
  const establecimientosfavoritosQuery = useSuperQuery(repo.getFavoritos, {
    showSpinner: true,
  });
  const {params} =
    useRoute<RouteProp<EstablecimientosNavigationType, 'detail'>>();
  const establecimientoQuery = useSuperQuery(() => repo.get(params.id), {
    showSpinner: true,
  });
  const addToFavorites = useSuperMutation(repo.addToFavorites, {
    showSpinner: true,
    showSuccessMessage: false,
    onMutationFinish: () => {
      establecimientosfavoritosQuery.reload();
    },
  });
  const removeFromFavorites = useSuperMutation(repo.removeFromFavorites, {
    showSpinner: true,
    showSuccessMessage: false,
    onMutationFinish: () => {
      establecimientosfavoritosQuery.reload();
    },
  });

  const disponibilidadHorariaQuery = useSuperQuery(
    async () => {
      if (daySelected === undefined || params.id === undefined) {
        return undefined;
      }
      return await repo.getHorarios(params.id, daySelected);
    },
    {showSpinner: true},
    [daySelected],
  );
  const createReservaMutation = async (reserva: ICreateReserva) => {
    const promise = reservaRepo.createReserva(reserva);
    modal.closeModal('EstablecimientoReservaBottomSheetComponent');

    if (establecimientoQuery.data?.seña === 0) {
      promise
        .then(() => {
          navigation.navigate('reservas');
        })
        .catch((err: Error) => {
          showMessage({
            title: 'Error',
            message: err.message ? err.message : 'Ha ocurrido un error',
            type: 'error',
          });
        });
    } else {
      // En safari no se puede abrir un browser como respuesta a algo asincronico
      // La solucion es abrir un browser primero y cuando la request termine, cambiarle la url
      navigation.navigate('reservas');
      const windowReference = window.open();
      promise
        .then(function (res) {
          windowReference!.location = res.preference_url;
          setTimeout(() => {
            if (windowReference?.location.href === 'about:blank') {
              // Esto pasa porque cuando se abre el browser, el celular puede abrir la aplicación de mercadopago en vez de mostrarlo en la pagina
              // Si esto pasa, queda la pestaña abierta con about:blank por arriba de la app cuando el usuario vuelve a la app
              windowReference?.close();
            }
          }, 5000);
        })
        .catch((err: Error) => {
          console.log(err.message);
          windowReference?.close();
          navigation.goBack();
          showMessage({
            title: 'Error',
            message: err.message ? err.message : 'Ha ocurrido un error',
            type: 'error',
          });
        });
    }
  };
  createReservaRef.current = createReservaMutation;

  useEffect(() => {
    if (disponibilidadHorariaQuery.data) {
      setDisponibilidadHoraria(disponibilidadHorariaQuery.data);
    }
  }, [disponibilidadHorariaQuery.data]);

  useEffect(() => {
    setDisponibilidadHorariaLoading(disponibilidadHorariaQuery.isLoading);
  }, [disponibilidadHorariaQuery.isLoading]);

  useEffect(() => {
    if (establecimientoQuery.data) {
      setSeña(establecimientoQuery.data.seña);
    }
  }, [establecimientoQuery.data]);

  const tiposCancha = [
    ...new Set(
      (establecimientoQuery.data?.canchas ?? []).map(cancha => cancha.tipo),
    ),
  ];

  const inFavorites = (establecimientosfavoritosQuery.data ?? []).some(e => {
    return e.id === Number(params.id);
  });

  return (
    <PageDetail
      source={
        establecimientoQuery.data !== undefined
          ? {uri: establecimientoQuery.data.foto}
          : Background
      }
      left={
        <PageDetail.BackButton
          onPress={() =>
            navigation.navigate('establecimientos', {
              screen: 'listado',
            })
          }
        />
      }
      right={
        inFavorites ? (
          <TouchableOpacity
            onPress={() => removeFromFavorites.mutate(params.id)}>
            <Icon name="heart" size={34} color="red500" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => addToFavorites.mutate(params.id)}>
            <Icon name="heart" size={34} color="white" />
          </TouchableOpacity>
        )
      }>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
        <PageDetail.Title>{establecimientoQuery.data?.nombre}</PageDetail.Title>
        {establecimientoQuery.data === undefined ||
        !establecimientoQuery.data.turno_libre_hoy ? null : (
          <Badge color="green500">Libre</Badge>
        )}
      </View>

      <PageDetail.Subtitle>
        {establecimientoQuery.data === undefined
          ? ''
          : `${establecimientoQuery.data.domicilio.calle} ${establecimientoQuery.data.domicilio.numero}, ${establecimientoQuery.data.domicilio.partido}`}
      </PageDetail.Subtitle>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 15,
        }}>
        <CalificacionComponent
          calificacion={establecimientoQuery.data?.calificacion ?? 0}
        />
        <Text style={{marginLeft: 30}}>
          ({establecimientoQuery.data?.total_reservas} reservas)
        </Text>
      </View>
      <PageDetail.ButtonGroup>
        <PageDetail.ButtonGroupItem
          iconName="location-outline"
          label="Ubicación"
          onPress={() => {
            const lat = establecimientoQuery.data?.domicilio.latitud;
            const lng = establecimientoQuery.data?.domicilio.longitud;
            if (lat === undefined || lng === undefined) {
              return;
            }
            navigationApp.open(lat, lng);
          }}
        />
        <PageDetail.ButtonGroupItem
          iconName="call-outline"
          label="Teléfono"
          onPress={() => {
            const telefono = establecimientoQuery.data?.telefono;
            if (telefono === undefined) {
              return;
            }
            whatsapp.sendMessage({phoneNumber: telefono});
          }}
        />
        {establecimientoQuery.data?.web ? (
          <PageDetail.ButtonGroupItem
            iconName="reader-outline"
            label="Página Web"
            onPress={() => {
              const url = establecimientoQuery.data?.web;
              if (url === undefined || url === null) {
                return;
              }
              webPage.open(url);
            }}
          />
        ) : null}
        {establecimientoQuery.data?.instagram ? (
          <PageDetail.ButtonGroupItem
            iconName="logo-instagram"
            label="Instagram"
            onPress={() => {
              const url = establecimientoQuery.data?.instagram;
              if (url === undefined || url === null) {
                return;
              }
              webPage.open(url);
            }}
          />
        ) : null}
      </PageDetail.ButtonGroup>
      <Section>
        <Section.Title>Tipos de canchas</Section.Title>
        <View style={styles.tiposContainer}>
          {tiposCancha.map(e => (
            <Badge color="dark700" size="xs" style={{marginRight: 10}} key={e}>
              {e}
            </Badge>
          ))}
        </View>
      </Section>
      <Section>
        <Section.Title>Descripción</Section.Title>
        <Section.Body style={{marginTop: 15}}>
          {establecimientoQuery.data?.descripcion}
        </Section.Body>
      </Section>
      <Section>
        <Section.Title>Servicios adicionales</Section.Title>
        <CheckContainer>
          {(establecimientoQuery.data?.servicios ?? []).map(e => (
            <CheckItemContainer key={e}>
              <CheckItem>{e}</CheckItem>
            </CheckItemContainer>
          ))}
        </CheckContainer>
      </Section>
      <Section>
        <Section.Title>Cantidad de canchas</Section.Title>
        <Section.Body style={{marginTop: 15}}>
          {establecimientoQuery.data?.nombre} tiene{' '}
          <Text color={'green500'}>
            {establecimientoQuery.data?.canchas.length}
          </Text>{' '}
          canchas
        </Section.Body>
      </Section>
      <View style={{marginTop: 30, paddingBottom: 20}}>
        <Button
          size="xl"
          onPress={() => {
            modal.openModal('EstablecimientoReservaBottomSheetComponent');
          }}>
          Reservar
        </Button>
      </View>
    </PageDetail>
  );
};

const CheckItemContainer = styled.View`
  width: 50%;
`;

const CheckContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const styles = StyleSheet.create({
  tiposContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  chipDiaContainer: {
    paddingVertical: 7,
    paddingHorizontal: 5,
    flexDirection: 'column',
    borderRadius: 15,
    alignItems: 'center',
  },
});
