import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {EstablecimientosPage} from '../pages/EstablecimientosPage';
import {EstablecimientosRepository} from 'app/establecimientos/services/establecimientosRepository';
import {EstablecimientoDetailPage} from 'app/establecimientos/pages/EstablecimientoDetailPage';
import {ReservasRepository} from 'app/reservas/services/reservasRepository';
import {EstablecimientoDateFilterPage} from '../pages/EstablecimientoDateFilterPage';
import {Icon} from '@vadiun/react-native-vaporeon';
import {TouchableOpacity} from 'react-native';
import {Link} from '@react-navigation/native';

export type EstablecimientosNavigationType = {
  listado: {fecha: string; hora: string; duracion: string} | undefined;
  detail: {id: number};
  filtroFechas: {fecha: string; hora: string; duracion: string} | undefined;
};

EstablecimientoDateFilterPage;
const Stack = createStackNavigator<EstablecimientosNavigationType>();

const repo = new EstablecimientosRepository();
const reservaRepo = new ReservasRepository();

export const EstablecimientosNavigation = () => (
  <Stack.Navigator>
    <Stack.Screen
      name="listado"
      options={{
        headerShown: false,
      }}>
      {() => <EstablecimientosPage repo={repo} />}
    </Stack.Screen>
    <Stack.Screen
      name="detail"
      options={{
        headerShown: false,
      }}>
      {() => (
        <EstablecimientoDetailPage repo={repo} reservaRepo={reservaRepo} />
      )}
    </Stack.Screen>
    <Stack.Screen
      name="filtroFechas"
      options={({navigation}) => ({
        title: '¿Qué horario queres reservar?',
        headerShadowVisible: false,
        headerLeft: (props: any) => (
          <Link
            to={{
              screen: 'establecimientos',
              params: {screen: 'listado'},
            }}>
            <Icon name="arrow-back" size={20} style={{marginLeft: 20}} />
          </Link>
        ),
      })}>
      {() => <EstablecimientoDateFilterPage />}
    </Stack.Screen>
  </Stack.Navigator>
);
