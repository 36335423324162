import {Link, RouteProp, useRoute} from '@react-navigation/native';
import {useSuperQuery} from '@vadiun/react-hooks';
import {
  FlatList,
  Surface,
  Tab,
  Text,
  TextInput,
} from '@vadiun/react-native-vaporeon';
import {EstablecimientoListItemComponent} from 'app/establecimientos/components/EstablecimientoListItemComponent';
import {EstablecimientoResumen} from 'app/establecimientos/models/EstablecimientoResumen';
import {EstablecimientosRepository} from 'app/establecimientos/services/establecimientosRepository';
import {useLocation} from 'app/main/services/useLocation';
import React, {useState} from 'react';
import {View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {RefreshControl} from 'react-native-web-refresh-control';
import styled from 'styled-components/native';
import {EstablecimientosNavigationType} from '../navigation';

interface Props {
  repo: EstablecimientosRepository;
}

export const EstablecimientosPage = ({repo}: Props) => {
  const getLocation = useLocation();
  const [chipSelected, setChipSelected] = useState(0);
  const route =
    useRoute<RouteProp<EstablecimientosNavigationType, 'listado'>>();

  const establecimientosQuery = useSuperQuery(
    async () => {
      try {
        const location = await getLocation();
        return repo.getAll({
          longitude: location?.coords.longitude ?? null,
          latitude: location?.coords.latitude ?? null,
          blindex: chipSelected === 2,
          favorites: chipSelected === 3,
          turnosLibresHoy: chipSelected === 1,
          fecha: route.params?.fecha,
          hora: route.params?.hora,
          duracion: route.params?.duracion,
        });
      } catch {
        return repo.getAll({
          longitude: null,
          latitude: null,
          blindex: chipSelected === 2,
          favorites: chipSelected === 3,
          turnosLibresHoy: chipSelected === 1,
          fecha: route.params?.fecha,
          hora: route.params?.hora,
          duracion: route.params?.duracion,
        });
      }
    },
    {},
    [chipSelected, route.params],
  );

  const [filter, setFilter] = useState({
    name: '',
  });

  const establecimientos = (
    (establecimientosQuery.data as EstablecimientoResumen[]) ?? []
  ).filter(f => f.nombre.toLowerCase().includes(filter.name.toLowerCase()));

  /*if (permissionGranted === false) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 35,
        }}>
        <Text style={{textAlign: 'center'}}>
          Para encontrar las canchas más cercanas necesitamos que apruebe el
          permiso de localización
        </Text>
        <Button onPress={requestPermission} style={{marginTop: 50}}>
          Continuar
        </Button>
      </View>
    );
  }*/
  return (
    <>
      <Container>
        <FlatList<EstablecimientoResumen>
          showsVerticalScrollIndicator={false}
          renderItem={({item}) => (
            <TouchableOpacity style={{marginHorizontal: 35}}>
              <Link to={`/establecimientos/detail/${item.id}`}>
                <Surface
                  elevation={2}
                  style={{borderRadius: 20, width: '100%'}}>
                  <EstablecimientoListItemComponent establecimiento={item} />
                </Surface>
              </Link>
            </TouchableOpacity>
          )}
          refreshControl={
            <RefreshControl
              refreshing={
                establecimientosQuery.isLoading &&
                !establecimientosQuery.isFirstLoad
              }
              onRefresh={establecimientosQuery.reload}
            />
          }
          ListFooterComponent={<View style={{marginBottom: 35}} />}
          ListHeaderComponent={
            <View style={{marginTop: 35}}>
              <Filters
                chipSelected={chipSelected}
                setChipSelected={setChipSelected}
                filterText={filter.name}
                setFiltertext={name =>
                  setFilter(prevValue => ({...prevValue, name}))
                }
                filters={route.params}
              />
              <Text
                style={{marginBottom: 10, marginHorizontal: 35}}
                color="gray500">
                Resultados encontrados ({establecimientos.length})
              </Text>
            </View>
          }
          ItemSeparatorComponent={() => <View style={{marginBottom: 20}} />}
          loading={establecimientosQuery.isLoading}
          data={establecimientos}
        />
      </Container>
    </>
  );
};

const Filters = (props: {
  filterText: string;
  setFiltertext: (value: string) => void;
  chipSelected: number;
  setChipSelected: (value: number) => void;
  filters: {fecha: string; hora: string; duracion: string} | undefined;
}) => {
  const {filterText, setFiltertext, chipSelected, setChipSelected, filters} =
    props;

  return (
    <>
      <View style={{paddingHorizontal: 35}}>
        <TextInput
          placeholder="Buscar por nombre"
          value={filterText}
          onChangeText={setFiltertext}
          left={<TextInput.Icon name="search-outline" />}
          right={
            <Link
              to={{
                screen: 'filtroFechas',
                params: {
                  fecha: filters?.fecha,
                  hora: filters?.hora,
                  duracion: filters?.duracion,
                },
              }}>
              <TextInput.Icon name="options-outline" />
            </Link>
          }
        />
      </View>
      <View style={{marginVertical: 10}}>
        <Tab<number> value={chipSelected} onChange={setChipSelected} scrollable>
          <Tab.Item
            size="md"
            activeColor="light50"
            value={0 as any}
            style={{marginLeft: 35}}>
            Todos
          </Tab.Item>
          <Tab.Item size="md" activeColor="light50" value={1 as any}>
            Turnos Libres
          </Tab.Item>
          <Tab.Item size="md" activeColor="light50" value={2 as any}>
            Blindex
          </Tab.Item>
          <Tab.Item
            size="md"
            activeColor="light50"
            value={3 as any}
            style={{marginRight: 35}}>
            Favoritos
          </Tab.Item>
        </Tab>
      </View>
    </>
  );
};

const Container = styled.View`
  flex: 1;
`;
