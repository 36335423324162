// @ts-ignore
import {PRODUCTION} from 'react-native-dotenv';

const develop = {
  backEnd: 'https://back.mipadel.com.ar/api/',
};

const production = {
  backEnd: 'https://back.mipadel.com.ar/api/',
};

const environment = PRODUCTION === 'true' ? production : develop;

export default environment;
