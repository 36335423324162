import {Card, useTheme} from '@vadiun/react-native-vaporeon';
import React from 'react';
import {Image, View} from 'react-native';
import {EstablecimientoResumen} from 'app/establecimientos/models/EstablecimientoResumen';
import {Icon, Text} from '@vadiun/react-native-vaporeon';
import {CalificacionComponent} from 'app/establecimientos/components/CalificacionComponent';

interface Props {
  establecimiento: EstablecimientoResumen;
}

export const EstablecimientoListItemComponent = ({establecimiento}: Props) => {
  return (
    <Card
      image={
        <Card.Image
          source={{uri: establecimiento.foto}}
          style={{width: 90, height: 80}}
        />
      }
      badge={
        establecimiento.turno_libre_hoy ? (
          <Card.Badge color="green500">Libre</Card.Badge>
        ) : null
      }
      elevation={0}
      title={<Card.Title>{establecimiento.nombre}</Card.Title>}
      subtitle={
        <Card.Subtitle>{`${establecimiento.domicilio.calle} ${establecimiento.domicilio.numero}, ${establecimiento.domicilio.partido}`}</Card.Subtitle>
      }>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}>
        <CalificacionComponent calificacion={establecimiento.calificacion} />
        {establecimiento.lejania > 100 ? null : (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Icon name={'navigate-outline'} style={{marginRight: 10}} />
            <Text>{establecimiento.lejania} km.</Text>
          </View>
        )}
      </View>
    </Card>
  );
};
