import {IEstablecimientoDomicilio} from 'app/establecimientos/models/EstablecimientoResumen';
import {Cancha} from 'app/establecimientos/models/Cancha';
import {IEstablecimientoServicio} from 'app/establecimientos/models/EstablecimientoServicio';

interface IEstablecimiento {
  id: number;
  nombre: string;
  calificacion: number;
  domicilio: IEstablecimientoDomicilio;
  lejania: number;
  turno_libre_hoy: boolean;
  canchas: Cancha[];
  descripcion: string;
  servicios: IEstablecimientoServicio[];
  foto: string;
  web: string | null;
  instagram: string | null;
  telefono: string;
  total_reservas: number;
  seña: number;
}

export class Establecimiento {
  id: number;
  nombre: string;
  calificacion: number;
  domicilio: IEstablecimientoDomicilio;
  lejania: number;
  turno_libre_hoy: boolean;
  canchas: Cancha[];
  descripcion: string;
  servicios: IEstablecimientoServicio[];
  foto: string;
  web: string | null;
  instagram: string | null;
  telefono: string;
  total_reservas: number;
  seña: number;

  constructor(args: IEstablecimiento) {
    this.id = args.id;
    this.nombre = args.nombre;
    this.calificacion = args.calificacion;
    this.domicilio = args.domicilio;
    this.lejania = args.lejania;
    this.turno_libre_hoy = args.turno_libre_hoy;
    this.canchas = args.canchas;
    this.descripcion = args.descripcion;
    this.servicios = args.servicios;
    this.foto = args.foto;
    this.web = args.web;
    this.instagram = args.instagram;
    this.telefono = args.telefono;
    this.total_reservas = args.total_reservas;
    this.seña = args.seña;
  }
}
