import {Jugador} from 'app/jugador/models/Jugador';
import {httpClient} from 'app/main/services/httpClient';

export const getJugadorMapper = (x: any): Jugador => new Jugador(x);

export interface IUpdateJugador {
  email: string;
  nombre: string;
  telefono: string;
}

export const updateJugadorMapper = (x: IUpdateJugador) => x;

export class JugadorRepository {
  getAll = async () => {
    const res = await httpClient.get('jugadores');
    return res.map(getJugadorMapper);
  };

  get = async (id: string) => {
    const res = await httpClient.get(`jugadores/${id}`);
    return getJugadorMapper(res);
  };

  update = async (x: IUpdateJugador) =>
    httpClient.put(`jugadores/me`, updateJugadorMapper(x));
}
