import {Cancha} from 'app/establecimientos/models/Cancha';
import {Establecimiento} from 'app/establecimientos/models/Establecimiento';
import {Moment} from 'moment';

interface IReserva {
  establecimiento: Establecimiento;
  cancha: Cancha;
  fecha_inicio: Moment;
  fecha_fin: Moment;
  precio: number;
  id: string;
  monto_pagado: number;
}

export class Reserva {
  establecimiento: Establecimiento;
  cancha: Cancha;
  fecha_inicio: Moment;
  fecha_fin: Moment;
  precio: number;
  id: string;
  monto_pagado: number;

  constructor(args: IReserva) {
    this.establecimiento = args.establecimiento;
    this.cancha = args.cancha;
    this.precio = args.precio;
    this.fecha_inicio = args.fecha_inicio;
    this.fecha_fin = args.fecha_fin;
    this.id = args.id;
    this.monto_pagado = args.monto_pagado;
  }
}
