interface ICancha {
  id: number;
  numero: number;
  tipo: string;
  es_techada: boolean;
  tiene_iluminacion: boolean;
  establecimiento_id: number;
  precio: number;
}

export class Cancha {
  id: number;
  numero: number;
  tipo: string;
  es_techada: boolean;
  tiene_iluminacion: boolean;
  establecimiento_id: number;
  precio: number;

  constructor(args: ICancha) {
    this.id = args.id;
    this.numero = args.numero;
    this.tipo = args.tipo;
    this.es_techada = args.es_techada;
    this.tiene_iluminacion = args.tiene_iluminacion;
    this.establecimiento_id = args.establecimiento_id;
    this.precio = args.precio;
  }
}
