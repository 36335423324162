import {Linking} from 'react-native';

export const useNavigationApp = () => {
  function open(lat: number, lng: number) {
    const latLng = `${lat},${lng}`;
    let url = '';
    if (
      navigator.userAgent.indexOf('iPhone') != -1 ||
      navigator.userAgent.indexOf('iPod') != -1 ||
      navigator.userAgent.indexOf('iPad') != -1
    )
      url = `comgooglemapsurl://?q=${latLng}`;
    else url = `https://www.google.com/maps/dir/?api=1&destination=${latLng}`;

    Linking.openURL(url!);
  }

  return {
    open,
  };
};
