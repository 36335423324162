import {createStackNavigator} from '@react-navigation/stack';
import {ChangePasswordPage} from 'app/jugador/pages/ChangePasswordPage';
import {EditJugadorPage} from 'app/jugador/pages/EditJugadorPage';
import {JugadorRepository} from 'app/jugador/services/jugadorRepository';
import {MorePage} from '../pages/MorePage';

export type MoreNavigationType = {
  edit: undefined;
  root: undefined;
  'change-password': undefined;
};

const Stack = createStackNavigator<MoreNavigationType>();

const jugadorRepo = new JugadorRepository();

export const MoreNavigation = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="root"
        options={{
          headerShown: false,
        }}
        component={MorePage}
      />
      <Stack.Screen
        name="edit"
        options={{
          title: 'Edición',
        }}>
        {() => <EditJugadorPage repo={jugadorRepo} />}
      </Stack.Screen>
      <Stack.Screen
        name="change-password"
        options={{
          title: 'Cambiar contraseña',
        }}>
        {() => <ChangePasswordPage />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
