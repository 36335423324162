import {Link} from '@react-navigation/native';
import {useMutation} from '@vadiun/react-hooks';
import {
  Button,
  FormikTextInput,
  TextInput,
  Text,
} from '@vadiun/react-native-eevee';
import {useAuth} from 'app/auth/services/useAuth';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import * as Yup from 'yup';

const Logo = require('assets/tennis.png');

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es invalido')
    .required('El email es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como minimo 4 caracteres'),
});
const LoginPage = () => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const {login} = useAuth();
  const loginMutation = useMutation(login);

  const initialValues = {
    email: '',
    password: '',
  };

  async function submit(values: {email: string; password: string}) {
    loginMutation.mutate(values);
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : 0}
      style={{flex: 1}}>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Image
            source={Logo}
            style={{width: 500, height: 100}}
            resizeMode="contain"
          />
        </View>

        <View style={styles.welcome}>
          <Text weight="bold" size="9xl">
            ¡Bienvenido!
          </Text>
          <Text color="gray400" size={'xl'} style={{marginVertical: 20}}>
            Inicie sesión para continuar
          </Text>
        </View>
        <Text>{loginMutation.error?.message}</Text>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={submit}>
          {({submitForm}) => (
            <View style={{flex: 1}}>
              <Field
                name="email"
                placeholder="Email"
                mode="flat"
                component={FormikTextInput}
                keyboardType="email-address"
                margin="lg"
                left={<TextInput.Icon name="mail-outline" />}
              />
              <Field
                name="password"
                placeholder="Contraseña"
                mode="flat"
                secureTextEntry={secureTextEntry}
                left={<TextInput.Icon name="lock-closed-outline" />}
                margin="lg"
                right={
                  secureTextEntry ? (
                    <TouchableOpacity onPress={() => setSecureTextEntry(false)}>
                      <TextInput.Icon name="eye-off-outline" />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => setSecureTextEntry(true)}>
                      <TextInput.Icon name="eye-outline" />
                    </TouchableOpacity>
                  )
                }
                component={FormikTextInput}
              />

              <View style={styles.buttons}>
                <Button
                  onPress={submitForm}
                  size="lg"
                  loading={loginMutation.isLoading}
                  disabled={loginMutation.isLoading}>
                  Ingresá
                </Button>
                <Link
                  to="/forgotPassword"
                  style={{marginHorizontal: 'auto', marginTop: 20}}>
                  <Text color="gray500">¿Olvidaste tu contraseña?</Text>
                </Link>
                <Text
                  color="gray500"
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                  }}>
                  ¿No tenés cuenta?{' '}
                  <Link to="/register">
                    {' '}
                    <Text color="primary500">Registrate</Text>
                  </Link>
                </Text>
              </View>
            </View>
          )}
        </Formik>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default LoginPage;

const styles = StyleSheet.create({
  container: {
    padding: 35,
    flexGrow: 1,
  },
  logo: {width: 200, height: 200},
  buttons: {
    justifyContent: 'flex-end',
  },
  welcome: {},
});
