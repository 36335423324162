import {Badge, Button, Card} from '@vadiun/react-native-vaporeon';
import React from 'react';
import {View} from 'react-native';
import {Icon, Text} from '@vadiun/react-native-vaporeon';
import {Reserva} from '../models/Reserva';
import moment from 'moment';

interface Props {
  reserva: Reserva;
  onCancel: () => void;
}

export const ReservaListItemComponent = ({reserva, onCancel}: Props) => {
  return (
    <Card
      image={
        <Card.Image
          source={{uri: reserva.establecimiento.foto}}
          style={{width: 90, height: 80}}
        />
      }
      elevation={0}
      title={<Card.Title>{reserva.establecimiento.nombre}</Card.Title>}
      subtitle={
        <View
          style={{flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
          <Icon name="calendar-outline" />
          <Text style={{marginHorizontal: 10}} color="text300">
            {reserva.fecha_inicio.format('DD/MM')}
          </Text>
          <Icon name="time-outline" />
          <Text style={{marginHorizontal: 10}} color="text300">
            {reserva.fecha_inicio.format('HH:mm')}
          </Text>
        </View>
      }>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
        }}>
        <Text>Duración del turno</Text>
        <Badge style={{marginLeft: 10}} color="light50" size="2xs">
          {reserva.fecha_fin.diff(reserva.fecha_inicio, 'minutes').toString()}
        </Badge>
      </View>
      <View
        style={{
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text size="xs" style={{marginRight: 7}} color="text300">
            Precio
          </Text>
          <Badge color="green500">{'$' + reserva.precio}</Badge>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text size="xs" style={{marginRight: 7}} color="text300">
            Seña
          </Text>
          <Badge color="dark600">{'$' + reserva.monto_pagado}</Badge>
        </View>
      </View>

      {reserva.fecha_inicio.isAfter(moment()) ? (
        <Button
          type="outlined"
          color="red500"
          style={{marginTop: 7}}
          onPress={onCancel}>
          Cancelar
        </Button>
      ) : null}
    </Card>
  );
};
