import {NavigationProp, useNavigation} from '@react-navigation/native';
import {useSuperMutation, useSuperQuery} from '@vadiun/react-hooks';
import {Button, FormikTextInput, TextInput} from '@vadiun/react-native-eevee';
import {useAuth} from 'app/auth/services/useAuth';
import {MoreNavigationType} from 'app/more/navigation';
import {Field, Formik} from 'formik';
import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import * as Yup from 'yup';
import {JugadorRepository} from '../services/jugadorRepository';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),
  nombre: Yup.string().required('El nombre es requerido'),
});

interface Props {
  repo: JugadorRepository;
}
export const EditJugadorPage = ({repo}: Props) => {
  const navigation = useNavigation<NavigationProp<MoreNavigationType>>();
  const jugadorQuery = useSuperQuery(() => repo.get('me'));
  const editMutation = useSuperMutation(repo.update, {
    messages: {
      success: {
        message: 'Usuario modificado',
      },
    },
  });

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={20}
      style={{flex: 1}}>
      <ScrollView contentContainerStyle={styles.container}>
        <Formik
          initialValues={{
            email: jugadorQuery.data?.email || '',
            nombre: jugadorQuery.data?.nombre || '',
            telefono: jugadorQuery.data?.telefono || '',
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={editMutation.mutate}>
          {({submitForm}) => (
            <View>
              <Field
                name="email"
                mode="flat"
                component={FormikTextInput}
                keyboardType="email-address"
                placeholder="Email"
                margin="md"
                left={<TextInput.Icon name="mail-outline" />}
              />
              <Field
                name="nombre"
                mode="flat"
                component={FormikTextInput}
                placeholder="Nombre"
                margin="md"
                left={<TextInput.Icon name="person-outline" />}
              />
              <Field
                name="telefono"
                mode="flat"
                component={FormikTextInput}
                placeholder="Teléfono"
                margin="md"
                type="number"
                left={<TextInput.Icon name="call-outline" />}
              />

              <Button
                onPress={submitForm}
                loading={editMutation.isLoading}
                style={{marginTop: 20}}
                size="lg"
                disabled={editMutation.isLoading}>
                Guardar
              </Button>
              <Button
                onPress={() => navigation.navigate('change-password')}
                type="outlined"
                style={{marginTop: 10, marginBottom: 86}}
                size="lg">
                Cambiar contraseña
              </Button>
            </View>
          )}
        </Formik>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 35,
    flexGrow: 1,
  },
  logo: {width: 200, height: 200},
  buttons: {
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  welcome: {
    justifyContent: 'flex-end',
    flex: 1,
  },
});
