import {useSuperMutation} from '@vadiun/react-hooks';
import {
  Button,
  FormikTextInput,
  Text,
  TextInput,
} from '@vadiun/react-native-eevee';
import {useAuth} from 'app/auth/services/useAuth';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Yup from 'yup';
import {Link, NavigationProp, useNavigation} from '@react-navigation/native';
import {AuthNavigationType} from '../navigation/AuthNavigation';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),
  name: Yup.string().required('El nombre es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como mínimo 4 caracteres'),
  passwordRepeat: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como mínimo 4 caracteres')
    .test(
      'match',
      'Las contraseñas no coinciden',
      (value, context) => context.parent.password === value,
    ),
});

export const RegisterPage = () => {
  const navigation = useNavigation<NavigationProp<AuthNavigationType>>();
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [secureTextEntryRepeat, setSecureTextEntryRepeat] = useState(true);
  const {register} = useAuth();
  const registerMutation = useSuperMutation(register, {
    showSuccessMessage: false,
    onMutationFinish(state) {
      if (!state.isError) {
        navigation.navigate('registerSuccess');
      }
    },
  });

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={20}
      style={{flex: 1}}>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.welcome}>
          <Text weight="bold" size="9xl">
            Registrate
          </Text>
          <Text color="gray400" size={'xl'} style={{marginVertical: 20}}>
            Creá una cuenta
          </Text>
        </View>
        <Formik
          initialValues={{
            email: '',
            password: '',
            passwordRepeat: '',
            name: '',
            telefono: '',
          }}
          validationSchema={Schema}
          onSubmit={registerMutation.mutate}>
          {({submitForm}) => (
            <View>
              <Field
                name="email"
                mode="flat"
                component={FormikTextInput}
                keyboardType="email-address"
                placeholder="Email"
                margin="md"
                left={<TextInput.Icon name="mail-outline" />}
              />
              <Field
                name="name"
                mode="flat"
                component={FormikTextInput}
                placeholder="Nombre"
                margin="md"
                left={<TextInput.Icon name="person-outline" />}
              />
              <Field
                name="telefono"
                mode="flat"
                component={FormikTextInput}
                placeholder="Teléfono"
                margin="md"
                type="number"
                left={<TextInput.Icon name="call-outline" />}
              />
              <Field
                name="password"
                mode="flat"
                secureTextEntry={secureTextEntry}
                component={FormikTextInput}
                placeholder="Contraseña"
                margin="md"
                left={<TextInput.Icon name="lock-closed-outline" />}
                right={
                  secureTextEntry ? (
                    <TouchableOpacity onPress={() => setSecureTextEntry(false)}>
                      <TextInput.Icon name="eye-off-outline" />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => setSecureTextEntry(true)}>
                      <TextInput.Icon name="eye-outline" />
                    </TouchableOpacity>
                  )
                }
              />
              <Field
                name="passwordRepeat"
                mode="flat"
                secureTextEntry={secureTextEntryRepeat}
                component={FormikTextInput}
                placeholder="Repita su contraseña"
                left={<TextInput.Icon name="lock-closed-outline" />}
                margin="md"
                right={
                  secureTextEntryRepeat ? (
                    <TouchableOpacity
                      onPress={() => setSecureTextEntryRepeat(false)}>
                      <TextInput.Icon name="eye-off-outline" />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      onPress={() => setSecureTextEntryRepeat(true)}>
                      <TextInput.Icon name="eye-outline" />
                    </TouchableOpacity>
                  )
                }
              />

              <Button
                onPress={submitForm}
                loading={registerMutation.isLoading}
                style={{marginTop: 20, marginBottom: 86}}
                size="lg"
                disabled={registerMutation.isLoading}>
                Registrar
              </Button>
              <Text
                color="gray500"
                style={{textAlign: 'center', marginTop: 20}}>
                ¿Ya tenés cuenta?{' '}
                <Link to="/login">
                  <Text color="primary500">Ingresá</Text>
                </Link>
              </Text>
            </View>
          )}
        </Formik>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 35,
    flexGrow: 1,
  },
  logo: {width: 200, height: 200},
  buttons: {
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  welcome: {
    justifyContent: 'flex-end',
    flex: 1,
  },
});
