import {
  NavigationContainer,
  Theme as NavigationTheme,
} from '@react-navigation/native';
import {AuthNavigation} from 'app/auth/navigation/AuthNavigation';
import {AuthContext, AuthProvider} from 'app/auth/services/useAuth';
import React, {useEffect} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {MainNavigation} from 'app/main/navigation';
import {
  Provider as EeveeProvider,
  defaultTheme,
  Theme,
  Message,
  Snackbar,
  LoadingSpinner,
  VerifyAction,
} from '@vadiun/react-native-eevee';
import {
  useFonts,
  Poppins_300Light,
  Poppins_500Medium,
  Poppins_700Bold,
} from '@expo-google-fonts/poppins';
import {
  MessageProvider,
  SnackbarProvider,
  SpinnerProvider,
  VerifyActionProvider,
} from '@vadiun/react-hooks';
import {ThemeProvider as StyledThemeProvider} from 'styled-components/native';
import AppLoading from 'expo-app-loading';
// @ts-ignore
import iconFont from './src/assets/Ionicons.ttf';

import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import {LinkingOptions} from '@react-navigation/native/lib/typescript/src/types';
import {EstablecimientoReservaBottomSheetComponent} from 'app/establecimientos/components/EstablecimientoReservaBottomSheetComponent';
import {EstablecimientoReservaProvider} from 'app/establecimientos/services/useEstablecimientoReservaProvider';
import * as Linking from 'expo-linking';

const theme: Theme = {
  typhography: {
    ...defaultTheme.typhography,
    fonts: {
      thin: 'Poppins_300Light', // These names must be the same used in the useFont hooks bellow
      medium: 'Poppins_500Medium',
      bold: 'Poppins_700Bold',
    },
  },
  colors: {
    ...defaultTheme.colors,
    dark900: '#242731',
    dark800: '#2D3039',
    primary50: '#F1F6CF',
    primary100: '#ECF2BE',
    primary200: '#E2EC9C',
    primary300: '#D9E579',
    primary400: '#CFDF57',
    primary500: '#C5D835',
    primary600: '#A2B222',
    primary700: '#778319',
    primary800: '#4C5410',
    primary900: '#222507',
    secondary50: '#F1F6CF',
    secondary100: '#ECF2BE',
    secondary200: '#E2EC9C',
    secondary300: '#D9E579',
    secondary400: '#CFDF57',
    secondary500: '#C5D835',
    secondary600: '#A2B222',
    secondary700: '#778319',
    secondary800: '#4C5410',
    secondary900: '#222507',
  },
  darkMode: true,
};

const navigationTheme: NavigationTheme = {
  dark: true,
  colors: {
    primary: defaultTheme.colors.primary500,
    background: '#242731',
    card: '#242731',
    text: defaultTheme.colors.light50,
    border: defaultTheme.colors.light100,
    notification: defaultTheme.colors.light100,
  },
};

const injectIcons = () => {
  const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: Ionicons;
}`;

  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
};

injectIcons();
const prefix = Linking.createURL('/');

const linking: LinkingOptions<any> = {
  prefixes: [prefix],
  config: {
    initialRouteName: 'login',
    screens: {
      login: 'login',
      forgotPassword: 'forgotPassword',
      register: 'register',
      reservaCreada: 'reservaCreada',
      registerSuccess: 'registerSuccess',
      main: {
        screens: {
          establecimientos: {
            screens: {
              listado: 'establecimientos/listado',
              detail: 'establecimientos/detail/:id',
              filtroFechas: 'establecimientos/filtroFechas',
            },
          },
          reservas: {
            screens: {
              listado: 'reservas/listado',
            },
          },
          more: {
            screens: {
              root: 'more/root',
              edit: 'more/edit',
              'change-password': 'more/change-password',
            },
          },
        },
      },
    },
  },
};

const modalStack = {
  EstablecimientoReservaBottomSheetComponent,
};

const App = () => {
  let [fontsLoaded] = useFonts({
    Poppins_300Light,
    Poppins_500Medium,
    Poppins_700Bold,
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    const root = document.getElementById('root');
    root?.appendChild(script);
    /* tslint:disable-next-line */
  }, []);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <SafeAreaProvider>
      <EstablecimientoReservaProvider>
        <EeveeProvider theme={theme} modalStack={modalStack as any}>
          <StyledThemeProvider theme={theme}>
            <MessageProvider component={Message}>
              <SnackbarProvider component={Snackbar}>
                <SpinnerProvider component={LoadingSpinner}>
                  <VerifyActionProvider component={VerifyAction}>
                    <AuthProvider>
                      <NavigationContainer
                        theme={navigationTheme}
                        linking={linking}>
                        <AuthContext.Consumer>
                          {({isAuthenticated}) =>
                            isAuthenticated === undefined ? (
                              <AppLoading />
                            ) : isAuthenticated === true ? (
                              <MainNavigation />
                            ) : (
                              <AuthNavigation />
                            )
                          }
                        </AuthContext.Consumer>
                      </NavigationContainer>
                    </AuthProvider>
                  </VerifyActionProvider>
                </SpinnerProvider>
              </SnackbarProvider>
            </MessageProvider>
          </StyledThemeProvider>
        </EeveeProvider>
      </EstablecimientoReservaProvider>
    </SafeAreaProvider>
  );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// "rewrites": [
//     { "source": "/**", "destination": "/index.html"}
//   ]
