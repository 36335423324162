import React from 'react';
import LottieView from 'lottie-react-native';
import styled from 'styled-components/native';
import {Button, Text} from '@vadiun/react-native-vaporeon';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {AuthNavigationType} from '../navigation/AuthNavigation';

export const RegisterSuccessPage = () => {
  const navigation = useNavigation<NavigationProp<AuthNavigationType>>();
  return (
    <Container>
      <IconContainer>
        <LottieView
          source={require('../../../assets/success.json')}
          autoPlay
          loop={false}
        />
      </IconContainer>
      <Title size="7xl" weight="bold">
        Usuario creado
      </Title>
      <Subtitle color="text400" size="lg">
        Hemos enviado un correo a su email para verificar su cuenta. Luego podrá
        iniciar sesión.
      </Subtitle>
      <Button size="lg" onPress={() => navigation.navigate('login')}>
        Continuar
      </Button>
    </Container>
  );
};

const Container = styled.View`
  background-color: ${({theme}) =>
    theme.darkMode ? theme.colors.dark800 : theme.colors.white};
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1;
`;

const Title = styled(props => <Text {...props} />)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const Subtitle = styled(props => <Text {...props} />)`
  text-align: center;
  margin-bottom: 50px;
`;

const IconContainer = styled.View`
  height: 100px;
  width: 100px;
`;
