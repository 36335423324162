import React from 'react';
import {View} from 'react-native';
import LottieView from 'lottie-react-native';
import Success from 'assets/success.json';
import {Text} from '@vadiun/react-native-vaporeon';

export const ReservationSuccessPage = () => {
  return (
    <View
      style={{
        flex: 1,
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
      }}>
      <LottieView source={Success} autoPlay style={{width: 200}} />
      <Text style={{textAlign: 'center'}} size="5xl">
        Reserva creada
      </Text>
      <Text style={{textAlign: 'center'}} size="xl">
        Podrás ver tu reserva en la app de Mi Padel
      </Text>
    </View>
  );
};
