import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import MisReservasPage from '../pages/MisReservasPage';
import {ReservationSuccessPage} from '../pages/ReservationSuccessPage';
import {ReservasRepository} from '../services/reservasRepository';

export type ReservasNavigationType = {
  listado: undefined;
  detail: {id: number};
  creationSuccess: undefined;
};

const Stack = createStackNavigator<ReservasNavigationType>();

const repo = new ReservasRepository();

export const ReservasNavigation = () => (
  <Stack.Navigator>
    <Stack.Screen
      name="listado"
      options={{
        headerShown: false,
      }}>
      {() => <MisReservasPage repo={repo} />}
    </Stack.Screen>
  </Stack.Navigator>
);
