export interface IEstablecimientoDomicilio {
  calle: string;
  departamento: string;
  latitud: number;
  localidad: string;
  longitud: number;
  numero: number;
  partido: string;
  piso: string;
  provincia: string;
}

interface IEstablecimientoResumen {
  id: number;
  nombre: string;
  calificacion: number;
  domicilio: IEstablecimientoDomicilio;
  lejania: number;
  turno_libre_hoy: boolean;
  foto: string;
}

export class EstablecimientoResumen {
  id: number;
  nombre: string;
  calificacion: number;
  domicilio: IEstablecimientoDomicilio;
  lejania: number;
  turno_libre_hoy: boolean;
  foto: string;

  constructor(args: IEstablecimientoResumen) {
    this.id = args.id;
    this.nombre = args.nombre;
    this.calificacion = args.calificacion;
    this.domicilio = args.domicilio;
    this.lejania = args.lejania;
    this.turno_libre_hoy = args.turno_libre_hoy;
    this.foto = args.foto;
  }
}
