import {createStackNavigator} from '@react-navigation/stack';
import LoginPage from 'app/auth/pages/LoginPage';
import React from 'react';
import {ForgotPasswordPage} from '../pages/ForgotPasswordPage';
import {RegisterPage} from '../pages/RegisterPage';
import {RegisterSuccessPage} from '../pages/RegisterSuccessPage';

export type AuthNavigationType = {
  login: undefined;
  register: undefined;
  forgotPassword: undefined;
  registerSuccess: undefined;
};

const Stack = createStackNavigator<AuthNavigationType>();

export const AuthNavigation = () => (
  <Stack.Navigator initialRouteName="login">
    <Stack.Screen
      name="login"
      options={{
        title: 'Login',
        headerShown: false,
      }}
      component={LoginPage}
    />
    <Stack.Screen
      name="forgotPassword"
      options={{
        title: '',
        headerShadowVisible: false,
      }}
      component={ForgotPasswordPage}
    />
    <Stack.Screen
      name="register"
      options={{
        title: '',
        headerShadowVisible: false,
      }}
      component={RegisterPage}
    />
    <Stack.Screen
      name="registerSuccess"
      options={{
        title: '',
        headerShadowVisible: false,
        headerShown: false,
      }}
      component={RegisterSuccessPage}
    />
  </Stack.Navigator>
);
